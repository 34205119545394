import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import Button from '../../components/Button/Button';
import Alert from '../../components/Alert/Alert';

const LicensingPoliciesPage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			buildersImg: file(relativePath: { eq: "builders_closeUp02.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

			vendorsImg: file(relativePath: { eq: "salesPeople.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

			ownersImg: file(relativePath: { eq: "helen-homebuyers.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			subheader={<FormattedMessage id='nav.licensingComp' />}
			header={<FormattedMessage id='nav.licensing' />}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.licensingDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'nav.licensing' })}
			/>
			<Container>
				<Row>
					<Col md={12}>
						<h2 className='underline'>
							<FormattedMessage id='licensing.header01' />
						</h2>

						<h3>
							<FormattedMessage id='licensing.subhdr01' />
						</h3>
						<p>
							<FormattedMessage id='licensing.content01' />
						</p>

						<h3>
							<FormattedMessage id='licensing.subhdr02' />
						</h3>
						<p>
							<a href='https://www.tarion.com/'>Tarion</a> <FormattedMessage id='licensing.content02A' />
						</p>

						<p>
							<FormattedMessage id='licensing.content02B' />
						</p>

						<p>
							<FormattedMessage id='licensing.content02C' />
						</p>

						<Button
							id='hcraRole'
							linkPath='/about/our-role/'
							text={<FormattedMessage id='licensing.role' />}
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={12} xl={6}>
						<Img fluid={data.buildersImg.childImageSharp.fluid} alt='' />
					</Col>

					<Col md={12} xl={6}>
						<h3>
							<FormattedMessage id='licensing.subhdr03' />
						</h3>
						<p>
							<FormattedMessage id='licensing.content03A' />
						</p>
						<ul>
							<li>
								<FormattedMessage id='licensing.list03A' />
							</li>
							<li>
								<FormattedMessage id='licensing.list03B' />
							</li>
							<li>
								<FormattedMessage id='licensing.list03C' />
							</li>
							<li>
								<FormattedMessage id='licensing.list03D' />
							</li>
						</ul>

						<p>
							<FormattedMessage id='licensing.content03B' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2 className='underline'>Fees</h2>

						<h3>Licensing Fees</h3>
						<table class='table' style={{ marginBottom: `0` }}>
							<thead>
								<tr>
									<th>Type of Fee</th>
									<th>Rate</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>New Licence – Umbrella</td>
									<td>$750</td>
									<td>
										This is a one-time, new licence fee for those applying as part of an umbrella
										group. Applying as part of an umbrella group means that the applicant shares at
										least one common principal, director, officer, or partner with another licensed
										vendor/builder.
									</td>
								</tr>
								<tr>
									<td>New Licence – Non-Umbrella</td>
									<td>$3,000</td>
									<td>
										This is a one-time, new licence fee for a new applicant with no corporate
										history as a licensee.
									</td>
								</tr>
								<tr>
									<td>Renewal</td>
									<td>$500</td>
									<td>
										This is the annual fee every licensee must pay to maintain their licence. The
										renewal fee is paid each year through the licence renewal process.
									</td>
								</tr>
								<tr>
									<td>Fast-Track Renewal</td>
									<td>$300</td>
									<td>
										This is the annual fee for a licensee the HCRA has qualified for the fast-track
										renewal process.
									</td>
								</tr>
								<tr>
									<td>Late Renewal (in addition to renewal fee)</td>
									<td>$600</td>
									<td>This is the penalty fee for submitting a late renewal application.</td>
								</tr>
							</tbody>
						</table>
					</Col>

					<Col md={12} style={{ marginTop: `2rem` }}>
						<p>
							<FormattedMessage id='licensing.content03F' />
						</p>

						<Alert header={<FormattedMessage id='commonHdrs.note' />}>
							<FormattedMessage id='licensing.content03C' />{' '}
							<b>
								<FormattedMessage id='licensing.content03D' />
							</b>
							. <FormattedMessage id='licensing.content03E' />
						</Alert>
					</Col>

					<Col md={12} style={{ marginTop: `2rem` }}>
						<h3>Per Home</h3>
						<table class='table'>
							<thead>
								<tr>
									<th>Type of Fee</th>
									<th>Rate</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>HCRA Regulatory Oversight Fee (per home)</td>
									<td>$145</td>
									<td>
										Collected on a per-home basis. <b>This fee is also subject to HST.</b>
									</td>
								</tr>
							</tbody>
						</table>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2 className='underline'>
							<FormattedMessage id='licensing.header05' />
						</h2>

						<h3>
							<FormattedMessage id='licensing.subhdr05A' />
						</h3>
						<p>
							<FormattedMessage id='licensing.content05A' />
						</p>

						<p>
							<FormattedMessage id='licensing.content05B' />
						</p>

						<p>
							<FormattedMessage id='licensing.content05C' />
						</p>

						<Button
							linkPath='../../../3. HCRA Advisory - Common Definition of Home v1.0 2021-01-21.pdf'
							text={<FormattedMessage id='licensing.homeGuide' />}
							linkExternal
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<h3>
							<FormattedMessage id='licensing.header06' />
						</h3>
						<p>
							<FormattedMessage id='licensing.content06A' />
						</p>
						<p>
							<FormattedMessage id='licensing.content06B' />
						</p>

						<Button
							linkPath='../../../4. HCRA Advisory - Common Definition of Builder v1.0 2021-01-21.pdf'
							text={<FormattedMessage id='licensing.builderGuide' />}
							linkExternal
						/>
					</Col>
				</Row>

				<Row className='pageRow' align='center'>
					<Col md={6}>
						<Img fluid={data.vendorsImg.childImageSharp.fluid} alt='' />
					</Col>
					<Col md={6}>
						<h3>
							<FormattedMessage id='licensing.header07' />
						</h3>
						<p>
							<FormattedMessage id='licensing.content07A' />
						</p>
						<p>
							<FormattedMessage id='licensing.content07B' />
						</p>

						<Button
							linkPath='../../../5. HCRA Advisory - Common Definition of Vendor v1.0 2021-01-21.pdf'
							text={<FormattedMessage id='licensing.vendorGuide' />}
							linkExternal
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<h3>
							<FormattedMessage id='licensing.header08' />
						</h3>
						<p>
							<FormattedMessage id='licensing.content08A' />
						</p>
						<p>
							<FormattedMessage id='licensing.content08B' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow' align='center'>
					<Col md={6}>
						<Img fluid={data.ownersImg.childImageSharp.fluid} alt='' />
					</Col>

					<Col md={6}>
						<h3>
							<FormattedMessage id='licensing.header09' />
						</h3>
						<p>
							<FormattedMessage id='licensing.content09A' />
						</p>

						<Button
							linkPath='../../../6. HCRA Advisory - Common Definition of Owner v1.0 2021-01-21.pdf'
							text={<FormattedMessage id='licensing.ownerGuide' />}
							linkExternal
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h3>
							<FormattedMessage id='licensing.header10' />
						</h3>
						<p>
							<FormattedMessage id='licensing.content10A' />
						</p>
						<p>
							<FormattedMessage id='licensing.content10B' />
						</p>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default LicensingPoliciesPage;
